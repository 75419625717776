import React, { useCallback } from "react";

import { useContext, useEffect, useState } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";
import { useCustomModal } from "../../hooks/useCustomModal";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";

import history from "../../services/history";
import { boolean, string } from "yup/lib/locale";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { UploadFiles } from "../../components/UploadFiles";
import { UploadHasFiles } from "../../components/UploadHasFiles";
import { UploadHasFile } from "../../components/UploadHasFile";
import moment from "moment";

// ? valigação do objeto do formulario
const schema = yup.object().shape({
    is_correct_address: yup.string().required("Essa resposta é obrigatória"),
    is_request_right: yup.string().required("Essa resposta é obrigatória"),
    is_request_ready: yup.string().required("Essa resposta é obrigatória"),
    is_packed: yup.string().required("Essa resposta é obrigatória"),

    // name_person: yup.string().when("is_packed", {
    //     is: (hasTable: any) => hasTable == true,
    //     then: yup.string().required("Campo obrigatório"),
    // }),

    // name_person: yup
    //     .string()
    //     .required("Essa resposta é obrigatória")
    // email: yup
    //     .string()
    //     .email("Digite um e-mail válido")
    //     .required("E-mail obrigatório")
});

interface IPreAuditoria {
    id: string;
    is_correct_address: boolean;
    is_request_right: boolean;
    is_request_ready: boolean;
    is_packed: boolean;
    solicitation_id: string;
}

interface IAddress {
    zip_code: string;
    address: string;
    number: string;
    district: string;
    description: string;
    city: string;
    state: string;
}

export default function PreAuditoria() {
    const [preAuditoria, setPreAuditoria] = useState<any>();
    const [address, setAddress] = useState<IAddress>();
    const [solicitationData, setSolicitationData] = useState<any>();

    // const [possuiAcompanhate, setPossuiAcompanhante] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState<any>();

    const [addressRight, setAdressRight] = useState<any>("");
    const [orderRight, setOrderRight] = useState<any>("");
    const [orderIs100, setOrderIs100] = useState<any>("");
    const [orderIsPacked, setOrderIsPacked] = useState<any>("");

    const modal = useCustomModal();

    let { id }: { id: string } = useParams();

    const { handleLogOut, auditor } = useContext(Context);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    // ? carrega dados da solicitação
    useEffect(() => {
        async function getPreAuditoria() {
            try {
                console.log("executou");
                // const { data } = await api.get(`audit/solicitation/${id}`);
                const solicitation = await api.get(`solicitations/${id}`);
                setPreAuditoria(solicitation.data.audit);
                setAddress(solicitation.data.address);
                console.log("dados ->", solicitation.data);
                setSolicitationData(solicitation.data);
            } catch {
                handleLogOut();
            }
        }

        getPreAuditoria();
    }, [id]);

    // ? redireciona em caso de no show ou de finalização da checklist
    useEffect(() => {
        function checkStatus() {
            try {
                if (solicitationData?.audit.status === "aprovado") {
                    history.push(`/checklist/${id}`);
                } else if (solicitationData?.audit.status === "reprovado") {
                    history.push(`/noshow/${id}`);
                }
            } catch {
                console.log("deu ruim");
            }

            // solicitationData?.audit.status === "aprovado"
            //     ?
            //     // history.push(`/checklist/${id}`)
            //     console.log("aaaaaaa")
            //     : solicitationData?.audit.status === "reprovado"
            //     ? history.push(`/noshow/${id}`)
            //     : console.log("aaaaaaa");
        }
        checkStatus();
    }, [solicitationData]);

    // Parte de Upload
    /*const selectFiles = useCallback(
        (event: any) => {
            const notFoundImage = [];
            // for (let i = 0; i < event.target.files.length; i++) {
            //     if (event.target.files[i].type.indexOf('image') < 0) {
            //         notFoundImage.push(event.target.files[i]);
            //     }
            // }
            // if (notFoundImage.length > 0) {

            // } else {
            setFilesToUpload(event.target.files);
            // }
        },
        [setFilesToUpload, filesToUpload]
    );*/

    // ? envia a preauditoria pra o back-end
    async function handleRegisterPreAuditoria(data: any) {

        const registerPreAuditor = {
            solicitation_id: solicitationData.id,
            isCorrectAddress: data.is_correct_address == "true" ? true : false,
            solicitationNumberIsCorrect:
                data.is_request_right == "true" ? true : false,
            order100Completed: data.is_request_ready == "true" ? true : false,
            order70packed: data.is_packed == "true" ? true : false,
            observation: !(!data.observation || /^\s*$/.test(data.observation)) ? data.observation : null
            // name_person: data.name_person,
        };


        try {
            const response = await api.put(
                `audit/update/${preAuditoria?.id}`,
                registerPreAuditor
            );

            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar a sua pré-auditoria",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                if (
                    registerPreAuditor.isCorrectAddress &&
                    registerPreAuditor.solicitationNumberIsCorrect &&
                    registerPreAuditor.order100Completed &&
                    registerPreAuditor.order70packed
                ) {
                    // Vá para a tela de avaliação
                    history.push(`/checklist/${id}`);
                    console.log("foi proaudit");
                } else {
                    history.push(`/noshow/${id}`);
                    console.log("foi noshow");
                }
            }
        } catch {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Não foi possível realizar a sua pré-auditoria",
                text: "",
                cancelButton: "",
                confirmButton: "",
            });
            // history.push("/");
        }
    }

    function titleCase(str: any) {
        var splitStr = str.toLowerCase().split(" ");

        for (var i = 0; i < splitStr.length; i++) {
            if (splitStr.length[i] < splitStr.length) {
                splitStr[i].charAt(0).toUpperCase();
            }

            str = splitStr.join(" ");
        }

        return str;
    }

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">Pré Auditoria</h1>
                {/* <Link to="/solicitacao-formulario">
                    <PrimaryButton title="Solicitar" />
                </Link> */}
            </div>
            <br />
            <br />
            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                    <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                        <div>
                            <h2 className="text-3xl font-extrabold text-gray-900">
                                Perguntas para Pré Auditoria
                            </h2>
                            <p className="mt-4 text-lg text-gray-500"></p>
                        </div>
                        <div className="mt-12 lg:mt-0 lg:col-span-2">
                            {" "}
                            <div className="mb-6 text-xl font-bold">
                                Informações da solicitação:
                            </div>
                            <div className="mb-6 flex flex-wrap gap-6">
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Número do pedido
                                    </label>
                                    <input
                                        type="text"
                                        value={
                                            solicitationData?.numberSolicitation
                                        }
                                        className=" mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Código de cadastro
                                    </label>
                                    <input
                                        type="text"
                                        value={
                                            solicitationData?.factory
                                                .factoryCode
                                        }
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Código do fornecedor
                                    </label>
                                    <input
                                        type="text"
                                        value={
                                            solicitationData?.factory
                                                .supplierCode
                                        }
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Razão social
                                    </label>
                                    <input
                                        type="text"
                                        value={
                                            solicitationData?.factory
                                                .companyName
                                        }
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        CNPJ
                                    </label>
                                    <input
                                        type="text"
                                        value={solicitationData?.factory.cnpj}
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Artigo
                                    </label>
                                    <input
                                        type="text"
                                        value={solicitationData?.article}
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Data
                                    </label>
                                    <input
                                        type="text"
                                        value={moment(
                                            solicitationData?.date_call
                                        )
                                            .utc()
                                            .format("DD-MM-YYYY")}
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Período
                                    </label>
                                    <input
                                        type="text"
                                        value={solicitationData?.period}
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {solicitationData?.product !== null
                                            ? "Descrição do produto"
                                            : "Descrição do conjunto/kit"}
                                    </label>
                                    <input
                                        type="text"
                                        value={
                                            solicitationData?.product !== null
                                                ? solicitationData?.product.name
                                                : solicitationData?.clothingSet
                                                    .name
                                        }
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Quantidade de peças
                                    </label>
                                    <input
                                        type="text"
                                        value={solicitationData?.quantity}
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Quantidade de packs
                                    </label>
                                    <input
                                        type="text"
                                        value={solicitationData?.packsAmount}
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Regime de inspeção
                                    </label>
                                    <input
                                        type="text"
                                        value={
                                            solicitationData?.level_avaliation ===
                                                "intermediario_atenuado"
                                                ? "Intermediário Atenuado"
                                                : solicitationData?.level_avaliation ===
                                                    "normal"
                                                    ? "Normal"
                                                    : "Severo"
                                        }
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Precificação
                                    </label>
                                    <input
                                        type="text"
                                        value={
                                            solicitationData?.level_of_pricing
                                        }
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Materia prima
                                    </label>
                                    <input
                                        type="text"
                                        value={solicitationData?.material}
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Tipo de embalagem
                                    </label>
                                    <input
                                        type="text"
                                        value={solicitationData?.kindOfPacking}
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nome do inspetor
                                    </label>
                                    <input
                                        type="text"
                                        value={
                                            solicitationData?.auditor.fullname
                                        }
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Pedido bonificado
                                    </label>
                                    <input
                                        type="text"
                                        value={solicitationData?.bonusOrder}
                                        className="mt-1 block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mb-20">
                                <label
                                    htmlFor="observacao"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Observação:
                                </label>
                                <div>
                                    <input
                                        type="text"
                                        {...register(
                                            "observation"
                                        )}
                                        // value=""
                                        // onKeyDown={(e) => setObservation(e.target.value)}
                                        className="mt-1 w-[100%] block w-fill border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"

                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mb-20">
                                <label
                                    htmlFor="numero_pedido"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Endereço
                                </label>
                                <div>
                                    {address &&
                                        address.address +
                                        ", " +
                                        address.number +
                                        ", " +
                                        address.district +
                                        ". " +
                                        address.city +
                                        " - " +
                                        address.state}
                                </div>
                            </div>
                            <div className="border-b-2 mb-10" />
                            <dl className="space-y-12">
                                <form
                                    className="space-y-8 divide-y divide-gray-200"
                                    action="#"
                                    method="PUT"
                                    id="auditorRegisterForm"
                                    onSubmit={handleSubmit(
                                        handleRegisterPreAuditoria
                                    )}
                                >
                                    <div>
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            O endereço {address?.address} nº{" "}
                                            {address?.number}, Cidade:{" "}
                                            {address?.city} e Estado:{" "}
                                            {address?.state} está correto?
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            <div className="flex items-center">
                                                <input
                                                    {...register(
                                                        "is_correct_address"
                                                    )}
                                                    id="is_correct_address_yes"
                                                    name="is_correct_address"
                                                    type="radio"
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                    value="true"
                                                // onChange={(e) =>
                                                //     setAdressRight(
                                                //         e.target.value
                                                //     )
                                                // }
                                                />
                                                <label
                                                    htmlFor="is_correct_address_yes"
                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Sim
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <input
                                                    {...register(
                                                        "is_correct_address"
                                                    )}
                                                    id="is_correct_address_no"
                                                    name="is_correct_address"
                                                    type="radio"
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                    value="false"
                                                // onChange={(e) =>
                                                //     setAdressRight(
                                                //         e.target.value
                                                //     )
                                                // }
                                                />
                                                <label
                                                    htmlFor="is_correct_address_no"
                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Não
                                                </label>
                                            </div>
                                            {errors.is_correct_address && (
                                                <span className="text-mainDarkRed">
                                                    Por favor selecione uma das
                                                    opções
                                                </span>
                                            )}
                                        </dd>
                                    </div>

                                    <div>
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            O número do pedido está correto?
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            <div className="flex items-center">
                                                <input
                                                    {...register(
                                                        "is_request_right"
                                                    )}
                                                    id="is_request_right_yes"
                                                    name="is_request_right"
                                                    type="radio"
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                    value="true"
                                                // onChange={(e) =>
                                                //     setOrderRight(
                                                //         e.target.value
                                                //     )
                                                // }
                                                />
                                                <label
                                                    htmlFor="is_request_right_yes"
                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Sim
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <input
                                                    {...register(
                                                        "is_request_right"
                                                    )}
                                                    id="is_request_right_no"
                                                    name="is_request_right"
                                                    type="radio"
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                    value="false"
                                                // onChange={(e) =>
                                                //     setOrderRight(
                                                //         e.target.value
                                                //     )
                                                // }
                                                />
                                                <label
                                                    htmlFor="is_request_right_no"
                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Não
                                                </label>
                                            </div>
                                            {errors.is_request_right && (
                                                <span className="text-mainDarkRed">
                                                    Por favor selecione uma das
                                                    opções
                                                </span>
                                            )}
                                        </dd>
                                    </div>

                                    <div>
                                        <dt className="text-lg leading-6 font-medium text-gray-900">
                                            O pedido está 100% finalizado?
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-500">
                                            <div className="flex items-center">
                                                <input
                                                    {...register(
                                                        "is_request_ready"
                                                    )}
                                                    id="is_request_ready_yes"
                                                    name="is_request_ready"
                                                    type="radio"
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                    value="true"
                                                // onChange={(e) =>
                                                //     setOrderIs100(
                                                //         e.target.value
                                                //     )
                                                // }
                                                />
                                                <label
                                                    htmlFor="is_request_ready_yes"
                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Sim
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <input
                                                    {...register(
                                                        "is_request_ready"
                                                    )}
                                                    id="is_request_ready_no"
                                                    name="is_request_ready"
                                                    type="radio"
                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                    value="false"
                                                // onChange={(e) =>
                                                //     setOrderIs100(
                                                //         e.target.value
                                                //     )
                                                // }
                                                />
                                                <label
                                                    htmlFor="is_request_ready_no"
                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Não
                                                </label>
                                            </div>
                                            {errors.is_request_ready && (
                                                <span className="text-mainDarkRed">
                                                    Por favor selecione uma das
                                                    opções
                                                </span>
                                            )}
                                        </dd>
                                    </div>

                                    <div className="flex">
                                        <div>
                                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                                O pedido está 70% embalado?
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">
                                                <div className="flex items-center">
                                                    <input
                                                        {...register(
                                                            "is_packed"
                                                        )}
                                                        id="is_packed_yes"
                                                        name="is_packed"
                                                        type="radio"
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                        value="true"
                                                    // onChange={(e) =>
                                                    //     setOrderIsPacked(
                                                    //         e.target.value
                                                    //     )
                                                    // }
                                                    />
                                                    <label
                                                        htmlFor="is_packed_yes"
                                                        className="ml-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Sim
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input
                                                        {...register(
                                                            "is_packed"
                                                        )}
                                                        id="is_packed_no"
                                                        name="is_packed"
                                                        type="radio"
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                        value="false"
                                                    // onChange={(e) =>
                                                    //     setOrderIsPacked(
                                                    //         e.target.value
                                                    //     )
                                                    // }
                                                    />
                                                    <label
                                                        htmlFor="is_packed_no"
                                                        className="ml-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Não
                                                    </label>
                                                </div>

                                                {errors.is_packed && (
                                                    <span className="text-mainDarkRed">
                                                        Por favor selecione uma
                                                        das opções
                                                    </span>
                                                )}
                                            </dd>
                                        </div>
                                    </div>

                                    <div className="w-full md:w-32">
                                        <PrimaryButton
                                            title="Confirmar"
                                            form="auditorRegisterForm"
                                        />
                                    </div>
                                </form>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
