import { useEffect, useState } from "react";
// @ts-ignore
import CurrencyInput from "react-currency-input-field";

interface IListProducts {
    defects: any[];
    setValue: Function;
    samples: number;
    value: any;
    product: number;
}

export default function ParamentrosList({
    setValue,
    value,
    product,
    defects,
    samples,
}: IListProducts) {
    const [columns, setColumns] = useState<any>([]);
    const [lines, setLines] = useState<any[]>([]);
    const [outputData, setOutputData] = useState<any[]>([]);
    const [alteration, setAlteration] = useState<any>();

    function removeDuplicates(arr: any) {
        return arr.filter(
            (item: any, index: number) => arr.indexOf(item) === index
        );
    }

    function generateArray(length: any) {
        const output: string[] = [];
        for (let i = 0; i < length; i++) {
            output.push("0");
        }
        return output;
    }

    // ? carregamento inicial
    useEffect(() => {
        /*const numberColumns = orderBy(
            removeDuplicates(defects.map((item: any) => item.statement)),
            (o) => {
                var v = parseInt(o, 10);
                return isNaN(v) ? o : v;
            },
            "asc"
        );
        const stringColumns = orderBy(
            removeDuplicates(defects.map((item: any) => item.statement)),
            (o) => {
                var v = parseInt(o, 10);
                return isNaN(v) ? o : v;
            },
            "desc"
        );*/
        setColumns(
            removeDuplicates(defects.map((item: any) => item.statement))
        );
        const output: any[] = [];
        removeDuplicates(defects.map((item: any) => item.parameter)).forEach(
            (item: string) => {
                for (let i = 0; i < samples; i++) {
                    output.push({
                        name: item,
                        sample: i,
                    });
                }
            }
        );
        setLines(
            output.map((line) => ({
                ...line,
                id: defects
                    .filter((item: any) => item.parameter === line.name)
                    .map((item: any) => {
                        return ({ id: item.id, value: "", size: item.statement })
                    }),
            }))
        );
        setOutputData(
            defects.map((item: any, idx: number) => ({
                id: item.id,
                value: generateArray(samples),
            }))
        );
    }, []);

    /*function updateFields(
        size: string,
        defect: string,
        sample: number,
        value: string,
        lineId: string
    ) {
        setOutputData(
            outputData.map((line) =>
                line.id === lineId
                    ? {
                          ...line,
                          value: line.value.map((item: number, idx: number) =>
                              idx === sample
                                  ? value === undefined
                                      ? "0"
                                      : value.replaceAll(" ", "")
                                  : item
                          ),
                      }
                    : line
            )
        );
    }*/

    useEffect(() => {

        let carry: any = [];
        lines.forEach((item: any) => {
            carry = [...carry, ...item.id];
        });
        let output: any = [];
        carry.forEach((item: any) => {
            if (output.map((options: any) => options.id).includes(item.id)) {
                output = output.map((out: any) =>
                    item.id === out.id
                        ? {
                            ...out,
                            values: [...out.values, Number(item.value)],
                        }
                        : out
                );
            } else {
                output.push({ id: item.id, values: [Number(item.value)] });
            }
        });
        // console.log("saida ->", output);
        setOutputData(output);
        setValue(output);
        // todo verificar e enviar saida
    }, [/*alteration*/ lines]);

    /*useEffect(() => {
        setValue(
            value.map((item: any, idx: number) =>
                idx === product ? outputData : item
            )
        );
        console.log("output->", outputData);
        console.log("line->", lines);
        console.log("columns->", columns);
    }, [outputData]);*/

    console.log(lines)
    return (
        <div className="w-full sm:px-8">
            <h2 className="text-xl font-semibold text-pink-700">
                Análise de Medidas
            </h2>
            <br />
            <div className="w-full overflow-x-auto border sm:rounded-lg">
                <table className="w-full divide-y divide-gray-300 min-w">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Descrição
                            </th>
                            {columns.map((name: string) => (
                                <th
                                    scope="col"
                                    className=" py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                    {name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white">

                        {lines.map((item, idx) => (
                            <tr className={"bg-gray-50"} key={idx}>
                                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                                    {item.name}
                                </td>
                                {columns.map(
                                    (tamanho: string, index: number) => (
                                        <td
                                            key={` ${idx}-${tamanho}`}
                                            className="text-sm text-gray-500 whitespace-nowrap sm:pr-8"
                                        >

                                            {defects
                                                .filter(
                                                    (input: any) =>
                                                        input.parameter ===
                                                        item.name
                                                )
                                                .map(
                                                    (input: any) =>
                                                        input.statement
                                                )
                                                .includes(tamanho) && (
                                                    <CurrencyInput
                                                        decimalSeparator="."
                                                        groupSeparator=" "
                                                        decimalsLimit={2}
                                                        max={1000000}
                                                        maxLength={500}
                                                        value={(item.id[index] != undefined && tamanho == item.id[index].size) ? item.id[index].value : null}
                                                        // anteriors
                                                        // value={item.id[index].value}
                                                        // @ts-ignore
                                                        onValueChange={(
                                                            value: string,
                                                            name: string
                                                        ) => {
                                                            setLines(
                                                                lines.map(
                                                                    (
                                                                        line: any,
                                                                        indx: number
                                                                    ) => {

                                                                        console.log('Item: ', item);
                                                                        console.log('Tamanho: ', tamanho);

                                                                        if (item.id[index] == undefined && line.name == item.name) {


                                                                            // let itemFound = item.id.filter((item_id: any) => item_id.size == tamanho);

                                                                            let newLine = line.id.map((ln: any, indx: number) => {

                                                                                if (tamanho == ln.size) {
                                                                                    ln.value = value;
                                                                                    return ln;
                                                                                }
                                                                                return ln;
                                                                            });

                                                                        }

                                                                        return (
                                                                            indx === idx
                                                                                ? {
                                                                                    ...line,
                                                                                    id: line.id.map(
                                                                                        (
                                                                                            id: any,
                                                                                            indx: number
                                                                                        ) =>
                                                                                            //anterior
                                                                                            // (indx === index)
                                                                                            //     ? {
                                                                                            //         ...id,
                                                                                            //         value: value,
                                                                                            //     }
                                                                                            //     : id

                                                                                            // atual
                                                                                            (tamanho == id.size)
                                                                                                ? {
                                                                                                    ...id,
                                                                                                    value: value,
                                                                                                }
                                                                                                : id
                                                                                    ),
                                                                                }
                                                                                : line
                                                                        )
                                                                    }

                                                                )
                                                            );
                                                        }}
                                                        required
                                                        className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm hidde focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                    />
                                                )}
                                            <div className="w-32 sm:hidden h-[1px] pr-4 flex justify-start items-center" />
                                        </td>
                                    )
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
