import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { CgCloseR } from "react-icons/cg";
import { HiOutlineUpload } from "react-icons/hi";
import { MdAddAPhoto } from "react-icons/md";
import api from "../../services/api";
// import { toast } from "react-toastify";

interface DropProps {
  original?: string;
  newPic: any;
  setNewPic: Function;
  disabled?: boolean;
  sectionIndex?: number;
  groupId?: any;
  checkId?: any;
}

export default function MyDropzone({
  original = "",
  newPic = null,
  setNewPic,
  // setId,
  // id,
  disabled = false,
  sectionIndex,
  groupId,
  checkId,
}: DropProps) {
  /*const [uri, setUri] = useState(original);
    const [loading, setLoading] = useState(false);*/
  const [state, setState] = useState<any[]>(newPic);

  console.log(state)

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      // Do something with the files
      async function uploadFile() {
        // setLoading(true);
        // ! verificar trava no tamanho da foto
        console.log("acceptedFiles", acceptedFiles);
        const filteredFiles = acceptedFiles.filter((item: any) =>
          item.type.includes("image")
        );
        if (sectionIndex !== undefined) {
          if (groupId !== undefined) {
            try {
              const data = new FormData();
              data.append(`${groupId}`, filteredFiles[0]);
              const response = await api.post(
                `checklistAvaliacao/avaliacao/${checkId}/files/add`,
                data
              );
              console.log("response ->", response);
              setNewPic([
                ...newPic,
                { id: response.data[0].id, file: response.data[0].file },
              ]);
              setState(
                newPic.map((item: any, idx: number) =>
                  idx === sectionIndex
                    ? [
                      ...item,
                      {
                        id: response.data[0].id,
                        file: response.data[0].file,
                      },
                    ]
                    : item
                )
              );
            } catch (e: any) {
              throw new Error(e);
            }
          } else {
            setNewPic(
              newPic.map((item: any, idx: number) =>
                idx === sectionIndex ? [...item, ...filteredFiles] : item
              )
            );
            setState(
              newPic.map((item: any, idx: number) =>
                idx === sectionIndex ? [...item, ...filteredFiles] : item
              )
            );
          }
        } else {
          setNewPic([...newPic, ...filteredFiles]);
          setState([...newPic, ...filteredFiles]);
        }
      }
      uploadFile();
    },
    [newPic, state]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  async function deletePic(index: number | string) {
    if (sectionIndex !== undefined) {
      if (groupId !== undefined) {
        try {
          // ! deletar imagem
          console.log("->", newPic[sectionIndex]);
          const response = await api.post(
            `checklistAvaliacao/avaliacao/${checkId}/files/remove`,
            {
              files: [newPic[sectionIndex][index].id],
            }
          );

          console.log("response ->", response);

          setNewPic(
            newPic.map((item: any, idx: any) =>
              idx === sectionIndex
                ? item.filter((subItem: any, indice: any) => indice !== index)
                : item
            )
          );
          setState(
            newPic.map((item: any, idx: any) =>
              idx === sectionIndex
                ? item.filter((subItem: any, indice: any) => indice !== index)
                : item
            )
          );
        } catch (e: any) {
          throw new Error(e);
        }
      } else {
        setNewPic(
          newPic.map((item: any, idx: any) =>
            idx === sectionIndex
              ? item.filter((subItem: any, indice: any) => indice !== index)
              : item
          )
        );
        setState(
          newPic.map((item: any, idx: any) =>
            idx === sectionIndex
              ? item.filter((subItem: any, indice: any) => indice !== index)
              : item
          )
        );
      }
    } else {
      setNewPic(newPic.filter((item: any, idx: number) => index !== idx));
      setState(newPic.filter((item: any, idx: number) => index !== idx));
    }
  }

  return (
    <>
      <div className="w-32 h-24">
        <div className="absolute text-xs text-gray-300 -translate-y-4">
          Limite por foto 15mb
        </div>
        {disabled ? (
          <div
            className={`h-full w-full ${disabled ? "opacity-75 cursor-not-allowed" : ""
              } cursor-pointer bg-gray-200 line-center hover:opacity-50 transition-all duration-500`}
          >
            <MdAddAPhoto color="#c30036" size="1.5em" />
          </div>
        ) : (
          <div
            className={`h-full w-full ${disabled ? "opacity-75 cursor-not-allowed" : ""
              } cursor-pointer rounded bg-gray-100 border border-gray-200 flex justify-center items-center hover:border-mainDarkRed transition-all duration-500`}
            {...getRootProps()}
          >
            <input {...getInputProps()} disabled={disabled} />
            {isDragActive ? (
              <HiOutlineUpload color="#2E2E2E" size="1.5em" />
            ) : (
              <HiOutlineUpload color="#c30036" size="1.5em" />
            )}
          </div>
        )}
      </div>
      {newPic !== null && sectionIndex === undefined
        ? state.map((item: any, idx: number) => (
          <div key={URL.createObjectURL(item)} className="relative">
            <div className="absolute w-full flex justify-end">
              <div
                onClick={() => deletePic(idx)}
                className="bg-white p-[2px] m-1 rounded opacity-100 cursor-pointer hover:opacity-80 transition-all duration-500"
              >
                <CgCloseR color={"#c30036"} />
              </div>
            </div>
            <img
              className="h-24 w-24 rounded"
              src={URL.createObjectURL(item)}
            />
          </div>
        ))
        : state.length !== undefined &&
        // @ts-ignore
        state[sectionIndex].map((item: any, idx: number) => (
          <div
            key={
              typeof item.file === "string"
                ? `${item.file}`
                : URL.createObjectURL(item)
            }
            className="relative"
          >
            <div className="absolute w-full flex justify-end">
              <div
                onClick={() =>
                  deletePic(typeof item.file === "string" ? item.id : idx)
                }
                className="bg-white p-[2px] m-1 rounded opacity-100 cursor-pointer hover:opacity-80 transition-all duration-500"
              >
                <CgCloseR color={"#c30036"} />
              </div>
            </div>
            <img
              className="h-24 w-24 rounded"
              src={
                typeof item.file === "string"
                  ? `${item.file}`
                  : URL.createObjectURL(item)
              }
            />
          </div>
        ))}
    </>
  );
}
