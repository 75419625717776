import React from 'react';
import { PaperClipIcon } from '@heroicons/react/solid';
import { useContext, useEffect, useState } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";
import { useCustomModal } from "../../hooks/useCustomModal";
import LoadingTable from "../../components/LoadingTable";


export default function ResultChecklist() {

    const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(true);
    const [solicitation, setSolicitation] = useState<any>();

    const modal = useCustomModal();

    let { id }: { id: string } = useParams();

    const { handleLogOut, auditor } = useContext(Context);

    useEffect(() => {
        async function resultChecklistInfo() {

            try {
                const resultReport = await api.get(`solicitations/generate/pdf/${id}`);
                const { data } = await api.get(`solicitations/${id}`);

                console.log(data);

                setSolicitation(data);
                setIsLoadingScreen(false);
            } catch {
                handleLogOut();
            }
        }

        resultChecklistInfo();
    }, [id]);

    return (


        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            {
                isLoadingScreen ?
                    <LoadingTable />
                    :
                    <>
                        <div className="px-4 py-5 sm:px-6">
                            <h2 className={`text-lg leading-6 font-medium text-gray-900 ${solicitation && solicitation.status == 'aprovado' ? 'text-lime-600' : 'text-orange-700'} `}>

                                {
                                    solicitation && (solicitation.status == 'aprovado' ? 'Aprovado' : 'Reprovado')
                                }
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Resultado da auditoria realizada.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Número do Pedido</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.numero_pedido}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Fabrica</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.factory.fullname}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Quantidade</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.quantity}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.factory.email}</dd>
                                </div>

                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Atendido por</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.audit.name_person}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Período</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.period}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Artigo</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.article}</dd>
                                </div>

                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Possui Tabela de medidas</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.has_table_measurement ? 'Sim' : 'Não'}</dd>
                                </div>

                                {
                                    solicitation && solicitation.has_table_measurement ?
                                        <>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Código da tabela</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.table_code}</dd>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Classificaçao do Artigo</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.article_classification}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Categoria do produto</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.category}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Material</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.material}</dd>
                                            </div>
                                        </>
                                }

                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Nível de Precificação</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.level_of_pricing}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">REGIME DE INSPEÇÃO</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{
                                        solicitation && solicitation.level_avaliation ?
                                            <>
                                                {(solicitation.level_avaliation == 'intermediario_atenuado') ? 'Intermediário e Atenuado' : ''}
                                                {(solicitation.level_avaliation == 'normal') ? 'Normal' : ''}
                                                {(solicitation.level_avaliation == 'severo') ? 'Severo' : ''}
                                            </>
                                            :
                                            <>
                                            </>
                                    }</dd>
                                </div>

                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Endereço</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {solicitation && solicitation.address.zip_code}  {solicitation && solicitation.address.address},
                                        &nbsp;{solicitation && solicitation.address.city},
                                        &nbsp;{solicitation && solicitation.address.state}
                                    </dd>
                                </div>

                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Observação</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{solicitation && solicitation.checklistText}</dd>
                                </div>

                                {/* <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">About</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                            qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                            pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                        </dd>
                    </div> */}

                                {
                                    solicitation && solicitation.filereport ?
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Arquivo</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">

                                                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                                        <div className="w-0 flex-1 flex items-center">
                                                            <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            <span className="ml-2 flex-1 w-0 truncate">Resultado.pdf</span>
                                                        </div>
                                                        <div className="ml-4 flex-shrink-0">
                                                            <a href={`${process.env.REACT_APP_PATH_FILES}${solicitation.filereport.file}`} target="_blank" download={`ResultadoInspeção`} className="font-medium text-indigo-600 hover:text-indigo-500">
                                                                Download
                                                            </a>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </dd>
                                        </div>
                                        :
                                        ''
                                }


                            </dl>
                        </div>
                    </>

            }

        </div>


    );
}