import { Dialog, Menu, Transition } from "@headlessui/react";
import {
    ChevronDownIcon,
    DocumentReportIcon,
    MenuAlt2Icon,
    XIcon
} from "@heroicons/react/outline";
import { Fragment, ReactNode, useContext, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/logo.png";
import { Context } from "../../context/AuthContext";

type LayoutProps = {
    component: ReactNode;
};

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function Layout({ component }: LayoutProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { handleLogOut, auditor } = useContext(Context);

    const location = useLocation();

    const userNavigation = [
        // { name: "Seu Perfil", href: "#" },
        // { name: "Configurações", href: "#" },
        {
            name: "Sair",
            href: "#",
            action: () => {
                handleLogOut();
            },
        },
    ];

    const navigation = [
        {
            name: "Solicitações",
            href: "/",
            icon: DocumentReportIcon,
            current:
                location.pathname.startsWith("/solicita") ||
                (location.pathname === "/" && true),
        },
        // {
        //     name: "Gestão de Checklist",
        //     href: "#",
        //     icon: ViewListIcon,
        //     current: false,
        // },
        // {
        //     name: "Produtos",
        //     href: "/produtos",
        //     icon: FolderIcon,
        //     current: location.pathname.startsWith("/produt") && true,
        // },
        // {
        //     name: "Conjunto de Produtos",
        //     href: "#",
        //     icon: CalendarIcon,
        //     current: false,
        // },
        // {
        //     name: "Defeitos",
        //     href: "#",
        //     icon: DocumentSearchIcon,
        //     current: false,
        // },
        // { name: "Auditor", href: "#", icon: PencilAltIcon, current: false },
        // {
        //     name: "Fabricas",
        //     href: "/fabricas",
        //     icon: ChartBarIcon,
        //     current: location.pathname.startsWith("/fabri") && true,
        // },
        // { name: "Clientes", href: "#", icon: UsersIcon, current: false },
        // {
        //     name: "Usuários",
        //     href: "/usuarios",
        //     icon: UserGroupIcon,
        //     current: location.pathname.startsWith("/usuario") && true,
        // },
    ];

    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-40 md:hidden"
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-40 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 pt-2 -mr-12">
                                            <button
                                                type="button"
                                                className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() =>
                                                    setSidebarOpen(false)
                                                }
                                            >
                                                <span className="sr-only">
                                                    Close sidebar
                                                </span>
                                                <XIcon
                                                    className="w-6 h-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex items-center flex-shrink-0 px-4">
                                        <img
                                            className="w-auto h-28"
                                            src={logo}
                                            alt="bureau logo"
                                        />
                                    </div>
                                    <div className="flex-1 h-0 mt-5 overflow-y-auto">
                                        <nav className="px-2 space-y-1">
                                            {navigation.map((item) => (
                                                <Link
                                                    to={item.href}
                                                    key={item.name}
                                                    className={classNames(
                                                        item.current
                                                            ? "bg-mainDarkRed text-white"
                                                            : "text-gray-300 hover:bg-mainGray hover:text-white",
                                                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current
                                                                ? "text-gray-300"
                                                                : "text-gray-400 group-hover:text-gray-300",
                                                            "mr-4 flex-shrink-0 h-6 w-6"
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div
                                className="flex-shrink-0 w-14"
                                aria-hidden="true"
                            >
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col flex-1 min-h-0 bg-white">
                        <div className="flex items-center justify-center flex-shrink-0 h-32 px-4 bg-white">
                            <img
                                className="w-auto h-28"
                                src={logo}
                                alt="bureau logo"
                            />
                        </div>
                        <div className="flex flex-col flex-1 overflow-y-auto">
                            <nav className="flex-1 px-2 py-4 space-y-1">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            item.current
                                                ? "border-l-4 border-mainDarkRed text-mainDarkRed"
                                                : "text-gray-500 hover:bg-mainGray hover:text-white rounded-md",
                                            "group flex items-center px-2 py-2 text-sm font-medium "
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current
                                                    ? "text-mainDarkRed"
                                                    : "text-gray-500 group-hover:text-gray-300 ",
                                                "mr-3 flex-shrink-0 h-6 w-6"
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </Link>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:pl-64">
                    <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white shadow">
                        <button
                            type="button"
                            className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon
                                className="w-6 h-6"
                                aria-hidden="true"
                            />
                        </button>
                        <div className="flex justify-between flex-1 px-4">
                            <div className="flex flex-1"></div>
                            <div className="flex items-center ml-4 md:ml-6">
                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="flex items-center max-w-xs gap-3 text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span className="sr-only">
                                                Open user menu
                                            </span>
                                            <p>{auditor?.fullname}</p>
                                            <ChevronDownIcon className="w-4 h-4" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {userNavigation.map((item) => (
                                                <Menu.Item key={item.name}>
                                                    {({ active }) => (
                                                        <a
                                                            href={item.href}
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-100"
                                                                    : "",
                                                                "block px-4 py-2 text-sm text-gray-700"
                                                            )}
                                                            onClick={
                                                                item?.action
                                                            }
                                                        >
                                                            {item.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <main className="flex-1">
                        <div className="py-6">
                            <div className="mx-auto max-w-7xl sm:px-6 md:px-8">
                                {/* Replace with your content */}
                                <div className="sm:py-4">{component}</div>
                                {/* /End replace */}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
