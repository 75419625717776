import Table from "../../components/Table";

import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";
import { SolicitationsProps } from "../../types/Solicitations";
import SolicitationsTable from "../../components/Table/SolicitationsTable";
import { Pagination } from "../../components/Pagination";

export default function Solicitation() {
    const [solicitationList, setSolicitationList] = useState<
        SolicitationsProps[]
    >([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const { handleLogOut, auditor } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    let { id }: { id: string } = useParams();

    useEffect(() => {
        async function getAuditors() {
            setSolicitationList([]);
            try {
                const { data } = await api.get(
                    `solicitations?auditor_id=${auditor?.id}&page=${page}&status=confirmado`
                );

                setPage(data.currentPage);
                setLastPage(data.lastPage);
                setSolicitationList(data.list);
            } catch (e: any) {
                console.log("erro->", e.message);
                if (e.message !== "Network Error") {
                    handleLogOut();
                }
            }
        }

        getAuditors();
    }, [id, reloadData, page]);

    return (
        <div>
            <div className="flex justify-between items-center px-2 sm:px-0">
                <h1 className="font-bold text-2xl">Solicitações</h1>
                {/* <Link to="/solicitacao-formulario">
                    <PrimaryButton title="Solicitar" />
                </Link> */}
            </div>

            {solicitationList.length ? (
                <div className="w-full overflow-x-auto px-2">
                    <SolicitationsTable list={solicitationList} />
                    <div className="w-full h-8 " />
                    <Pagination
                        initPage={1}
                        totalPage={lastPage}
                        page={page}
                        setPage={setPage}
                        path={`solicitations`}
                    />
                </div>
            ) : (
                // <LoadingTable />
                <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="py-16">
                        <div className="text-center">
                            <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide"></p>
                            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                No momento não temos inspeções agendadas
                            </h1>
                            <p className="mt-2 text-base text-gray-500"></p>
                        </div>
                    </div>
                </main>
            )}
        </div>
    );
}
