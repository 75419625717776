import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useState } from "react";

interface SolicitationProps {
    fullname: string;
    email: string;
    password: string;
    confirmPassword: string;
    department: string;
    cargo: string;
}

const schema = yup.object().shape({
    fullname: yup
        .string()
        .required("Nome é obrigatório")
        .min(3, "Mínimo 3 caracteres"),
    email: yup
        .string()
        .email("Digite um e-mail válido")
        .required("E-mail obrigatório"),
    password: yup
        .string()
        .required("Senha obrigatória")
        .min(6, "Mínimo 6 digitos"),
    confirmPassword: yup
        .string()
        .required("Confirme a senha")
        .oneOf([yup.ref("password"), null], "As senhas precisam ser iguais"),
    department: yup.string().required("Selecione um departamento"),
    cargo: yup.string().required("Selecione um cargo"),
});

export default function FactorySolicitationForm() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cargo, setCargo] = useState<string>("Operação");
    const modal = useCustomModal();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<SolicitationProps>({
        resolver: yupResolver(schema),
    });

    async function handleRegisterClient(data: SolicitationProps) {
        setIsLoading(true);
        const dataRegistration = {
            fullname: data.fullname,
            email: data.email,
            password: data.password,
            department: data.department,
            cargo: data.cargo,
        };
        try {
            const response = await api.post("users/add", dataRegistration);
            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                modal.setCustomModal({
                    status: true,
                    icon: "success",
                    title: "Usuário cadastrado com sucesso!",
                    text: "Agora você pode acessá-lo na área de usuários",
                    cancelButton: "",
                    confirmButton: "Ok",
                });
            }
            setIsLoading(false);
        } catch {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: "Não foi possível realizar o cadastro",
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Criar solicitação
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área para cadastro de solicitação
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form
                            action="#"
                            method="POST"
                            id="userRegisterForm"
                            onSubmit={handleSubmit(handleRegisterClient)}
                        >
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="fullname"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nome
                                    </label>
                                    <input
                                        {...register("fullname")}
                                        type="text"
                                        name="fullname"
                                        id="fullname"
                                        autoComplete="given-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.fullname && (
                                        <span className="text-mainDarkRed">
                                            {errors.fullname.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        E-mail
                                    </label>
                                    <input
                                        {...register("email")}
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.email && (
                                        <span className="text-mainDarkRed">
                                            {errors.email.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Senha
                                    </label>
                                    <input
                                        {...register("password")}
                                        type="password"
                                        name="password"
                                        id="password"
                                        autoComplete="password"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.password && (
                                        <span className="text-mainDarkRed">
                                            {errors.password.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="confirmPassword"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Confirme sua senha
                                    </label>
                                    <input
                                        {...register("confirmPassword")}
                                        type="password"
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        autoComplete="password"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.confirmPassword && (
                                        <span className="text-mainDarkRed">
                                            {errors.confirmPassword.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="department"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Departamento
                                    </label>
                                    <select
                                        {...register("department")}
                                        id="department"
                                        name="department"
                                        autoComplete="department-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) =>
                                            setCargo(e.target.value)
                                        }
                                    >
                                        <option>Operação</option>
                                        <option>Programação</option>
                                        <option>Suporte</option>
                                    </select>
                                    {errors.department && (
                                        <span className="text-mainDarkRed">
                                            {errors.department.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="cargo"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Cargo
                                    </label>
                                    <select
                                        {...register("cargo")}
                                        id="cargo"
                                        name="cargo"
                                        autoComplete="cargo-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    >
                                        {/* Operação */}
                                        {cargo === "Operação" && (
                                            <option>Inspetor</option>
                                        )}

                                        {/* Programação */}
                                        {cargo === "Programação" && (
                                            <option>Programador</option>
                                        )}

                                        {/* Suporte */}
                                        {cargo === "Suporte" && (
                                            <>
                                                <option>Administrador</option>
                                                <option>
                                                    Gestor de contrato
                                                </option>
                                            </>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            form="userRegisterForm"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path="/usuarios"
            />
        </>
    );
}
