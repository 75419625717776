import Table from "../../components/Table";

import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";
import { SolicitationsProps } from "../../types/Solicitations";

export default function FactorySolicitationForm() {
    const [solicitationList, setSolicitationList] = useState<
        SolicitationsProps[]
    >([]);

    const { handleLogOut, auditor } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    let { id }: { id: string } = useParams();

    useEffect(() => {
        async function getUsers() {
            setSolicitationList([]);
            try {
                console.log(auditor);
                const { data } = await api.get("solicitations");

                setSolicitationList(data.list);
            } catch {
                handleLogOut();
            }
        }

        getUsers();
    }, [id, reloadData]);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">Solicitações</h1>
                <Link to="/solicitacao-formulario">
                    <PrimaryButton title="Solicitar" />
                </Link>
            </div>

            {solicitationList.length ? (
                <Table list={solicitationList} />
            ) : (
                <LoadingTable />
            )}
        </div>
    );
}
