import React from "react";

import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useContext, useEffect, useState, useCallback } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, useParams, Redirect } from "react-router-dom";
import { useCustomModal } from "../../hooks/useCustomModal";

import history from "../../services/history";
import PrimaryButton from "../../components/Button/PrimaryButton";
import api from "../../services/api";
import { UploadFiles } from "../../components/UploadFiles";
import { UploadHasFiles } from "../../components/UploadHasFiles";
import MyDropzone from "../../components/UpDropzone";

const schema = yup.object().shape({
    noshowText: yup.string(),
});

export default function NoShow() {
    const [filesToUpload, setFilesToUpload] = useState<any>([]);
    const modal = useCustomModal();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasImage, setHasImage] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [pics, setPics] = useState<any>([]);
    const [text, setText] = useState<string>("");
    const [defaultImages, setDefaultImages] = useState<any>([]);

    let { id }: { id: string } = useParams();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    console.log("files", filesToUpload.length > 0 ? "sim" : "nao");

    useEffect(() => {
        filesToUpload.length > 0 ? setHasImage(true) : setHasImage(false);
        filesToUpload.length > 0 && setShowError(false);
    }, [filesToUpload]);

    useEffect(() => {
        async function getNoShow() {
            try {
                const response = await api.get(`solicitations/${id}`);

                setText(response.data.noshowText);
                console.log("imagens->", response.data.audit.images);
                setDefaultImages(response.data.audit.images);
            } catch (e) {
                console.log("error->", e);
            }
        }
        getNoShow();
    }, []);

    async function handleRegisterPreAuditoria(data: any) {
        if (defaultImages.length > 0) {
            history.push("/");
        } else {
            setIsLoading(true);

            const formData = new FormData();

            // const dataRegistro = {
            //     noshowText: data.noshowText,
            // };
            let dataRegistro = JSON.stringify({
                observation: data.noshowText,
            });
            if (pics.length > 0) {
                pics.forEach((file: any) => {
                    formData.append("images", file);
                });

                // formData.append("solicitationId", id);
            }
            formData.append("data", dataRegistro);
            try {
                console.log("dados->", formData);
                const response = await api.put(`audit/noshow/${id}`, formData);
                setIsLoading(false);
                history.push("/");
            } catch {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar a avaliação",
                    text: "",
                    cancelButton: "",
                    confirmButton: "",
                });
                setIsLoading(false);
            }
            setShowError(false);
        }
    }

    // Parte de Upload
    const selectFiles = useCallback(
        (event: any) => {
            const notFoundImage = [];
            // for (let i = 0; i < event.target.files.length; i++) {
            //     if (event.target.files[i].type.indexOf('image') < 0) {
            //         notFoundImage.push(event.target.files[i]);
            //     }
            // }
            // if (notFoundImage.length > 0) {

            // } else {
            setFilesToUpload([...filesToUpload, ...event.target.files]);
            // }
        },
        [setFilesToUpload, filesToUpload]
    );

    return (
        <>
            <div className="flex justify-between items-center">
                {/* <h1 className="font-bold text-2xl">No-Show</h1> */}
                {/* <Link to="/solicitacao-formulario">
                    <PrimaryButton title="Solicitar" />
                </Link> */}
            </div>
            <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                <div className="max-w-max mx-auto">
                    <main className="sm:flex">
                        <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl"></p>
                        <div className="sm:ml-6">
                            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                    No-show
                                </h1>
                                <p className="mt-1 text-base text-gray-500">
                                    Infelizmente não temos como continuar com o
                                    pedido, já que nem todos os requisitos da
                                    pré auditoria foi atendidos.
                                </p>
                            </div>
                            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                                {/*<a
                                    href={`/`}
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Voltar para o Inicio
                                </a>*/}
                            </div>

                            <form
                                className=""
                                action="#"
                                method="POST"
                                id="auditorRegisterForm"
                                onSubmit={handleSubmit(
                                    handleRegisterPreAuditoria
                                )}
                            >
                                <label
                                    htmlFor="noshowText"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Observação
                                </label>
                                <div className="mt-1">
                                    <textarea
                                        {...register("noshowText")}
                                        id="noshowText"
                                        name="noshowText"
                                        value={text}
                                        onChange={(e) =>
                                            setText(e.target.value)
                                        }
                                        rows={4}
                                        className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                        defaultValue={""}
                                    />
                                </div>
                                {defaultImages.length > 0 ? (
                                    <div className="flex mt-8 items-center justify-start flex-wrap gap-2 w-full">
                                        {defaultImages.map(
                                            (image: any, idx: number) => (
                                                <div
                                                    className="w-48 h-48 rounded-lg overflow-hidden bg-cover bg-center border border-red-500"
                                                    style={{
                                                        backgroundImage: `url(${image.file})`,
                                                    }}
                                                    key={idx}
                                                >
                                                    <img
                                                        src={image.file}
                                                        className="w-full h-full"
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex flex-wrap gap-4 mt-10 ...">
                                        <MyDropzone
                                            newPic={pics}
                                            setNewPic={setPics}
                                        />
                                    </div>
                                )}
                                {showError ? (
                                    <div className="text-red-500">
                                        Envio de imagem obrigatório
                                    </div>
                                ) : (
                                    <div className="text-red-500 "></div>
                                )}
                                <div className="w-full md:w-32 mt-10">
                                    <PrimaryButton
                                        title={
                                            defaultImages.length > 0
                                                ? "Voltar"
                                                : "Confirmar"
                                        }
                                        form="auditorRegisterForm"
                                    />
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
