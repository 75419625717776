import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { CgCloseR } from "react-icons/cg";
import { HiOutlineUpload } from "react-icons/hi";
import { MdAddAPhoto } from "react-icons/md";
import api from "../../services/api";
import Loading from "react-loading";
// import { toast } from "react-toastify";

interface DropProps {
  disabled?: boolean;
  fieldName: string;
  groupId?: any;
  checkId: any;
  initialFiles: any[];
}

export default function MyDropzone({
  // setId,
  // id,
  disabled = false,
  fieldName,
  groupId,
  checkId,
  initialFiles,
}: DropProps) {
  /*const [uri, setUri] = useState(original);*/
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<any[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      // Do something with the files
      async function uploadFile() {
        setLoading(true);
        // console.log("acceptedFiles", acceptedFiles);

        const filteredFiles = acceptedFiles.filter((item: any) =>
          item.type.includes("image")
        );


        // verify if the file is bigger than 15mb
        if (filteredFiles.length == 0) {
          toast.error("O arquivo deve ser do tipo imagem");
          setLoading(false);
          return;
        } else if (filteredFiles[0].size > 15728640) {
          toast.error("O arquivo deve ser menor que 15mb");
          return;
        } else {
          try {
            const data = new FormData();
            data.append(`${fieldName}`, filteredFiles[0]);
            console.log("groupId ->", groupId);
            if (groupId !== undefined) {
              data.append("grupoId", groupId);
            }
            const response = await api.post(
              `checklistAvaliacao/avaliacao/${checkId}/files/add`,
              data
            );
            console.log("response ->", response);
            setState([
              ...state,
              { id: response.data[0].id, file: response.data[0].file },
            ]);
            setLoading(false);
          } catch (e: any) {
            setLoading(false);
            toast.error("Erro ao enviar imagem");
            throw new Error(e);
          }
        }


      }
      uploadFile();
    },
    [state]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/heic': [],
      'image/jfif': [],
    },
    multiple: false
  });

  async function deletePic(index: number) {
    try {
      // ! deletar imagem
      console.log("->", state[index]);
      const response = await api.post(
        `checklistAvaliacao/avaliacao/${checkId}/files/remove`,
        {
          files: [state[index].id],
        }
      );

      console.log("response ->", response);
      setState(state.filter((item: any, idx: any) => idx !== index));
    } catch (e: any) {
      throw new Error(e);
    }
  }

  useEffect(() => {
    if (fieldName === "checklistImages") {
      setState(
        initialFiles.filter(
          (item: any) => item.type === null && item.groupId === null
        )
      );
    } else if (fieldName === "groupImages") {
      setState(
        initialFiles.filter(
          (item: any) => item.type === null && item.groupId === groupId
        )
      );
    } else if (fieldName === "dimensionalImages") {
      setState(
        initialFiles.filter(
          (item: any) => item.type === "Dimensional" && item.groupId === null
        )
      );
    }
  }, [initialFiles]);

  return (
    <>
      <div className="w-32 h-24">
        <div className="absolute text-xs text-gray-300 -translate-y-4">
          Limite por foto 15mb
        </div>
        {disabled ? (
          <div
            className={`h-full w-full ${disabled ? "opacity-75 cursor-not-allowed" : ""
              } cursor-pointer bg-gray-200 line-center hover:opacity-50 transition-all duration-500`}
          >
            <MdAddAPhoto color="#c30036" size="1.5em" />
          </div>
        ) : loading ? (
          <div
            className={`h-full w-full ${disabled ? "opacity-75 cursor-not-allowed" : ""
              } cursor-pointer rounded bg-gray-100 border border-gray-200 flex justify-center items-center hover:border-mainDarkRed transition-all duration-500`}
          >
            <Loading type="spinningBubbles" color="#c30036" />
          </div>
        ) : (
          <div
            className={`h-full w-full ${disabled ? "opacity-75 cursor-not-allowed" : ""
              } cursor-pointer rounded bg-gray-100 border border-gray-200 flex justify-center items-center hover:border-mainDarkRed transition-all duration-500`}
            {...getRootProps()}
          >
            <input {...getInputProps()} disabled={disabled} />
            {isDragActive ? (
              <HiOutlineUpload color="#2E2E2E" size="1.5em" />
            ) : (
              <HiOutlineUpload color="#c30036" size="1.5em" />
            )}
          </div>
        )}
      </div>
      {state.length > 0 &&
        state.length !== undefined &&
        // @ts-ignore
        state.map((item: any, idx: number) => (
          <div
            key={
              typeof item.file === "string"
                ? `${item.file}`
                : URL.createObjectURL(item)
            }
            className="relative"
          >
            <div className="absolute w-full flex justify-end">
              <div
                onClick={() => deletePic(idx)}
                className="bg-white p-[2px] m-1 rounded opacity-100 cursor-pointer hover:opacity-80 transition-all duration-500"
              >
                <CgCloseR color={"#c30036"} />
              </div>
            </div>
            <img
              className="h-24 w-24 rounded"
              src={
                typeof item.file === "string"
                  ? `${item.file}`
                  : URL.createObjectURL(item)
              }
            />
          </div>
        ))}
    </>
  );
}
