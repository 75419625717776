import { useEffect, useState } from "react";

interface IListProducts {
    manyProduct: any;
    register: any;
    setValue: any;
    isVisual?: boolean;
    setInput: (state: any[]) => void;
    product: number;
    input: any;
}

export default function DefectsList({
    manyProduct,
    register,
    setValue,
    isVisual,
    setInput,
    product,
    input,
}: IListProducts) {
    const [defectInputChildren, setDefectInputChildren] = useState<any[]>([]);
    // ? estado atualizado com os dados do bloco ja organizados
    const [listData, setListData] = useState<any[]>([]);

    useEffect(() => {
        setListData(
            manyProduct.map((item: any) => ({
                id: item.id,
                value: "0",
                comments: "",
            }))
        );
    }, [manyProduct]);

    useEffect(() => {
        setInput(
            defectInputChildren.map((item: any) => ({
                ...item,
                value: Number(item.value),
            }))
        );
    }, [defectInputChildren]);

    const [verificar, setVerificar] = useState<any>();

    const defeitoVisuais = manyProduct?.filter((defect: any) => {
        return defect.type == "Visual";
    });

    const defeitoLogisticos = manyProduct?.filter((defect: any) => {
        return defect.type == "Logistico";
    });

    function updateData(idx: number, newValue: string) {
        setListData(
            listData.map((item: any, index: number) =>
                index === idx ? { ...item, value: newValue } : item
            )
        );
    }
    function updateComment(idx: number, newComment: string) {
        setListData(
            listData.map((item: any, index: number) =>
                index === idx ? { ...item, comments: newComment } : item
            )
        );
    }

    useEffect(() => {
        setInput(
            input.map((item: any, idx: number) =>
                idx === product ? listData : item
            )
        );
    }, [listData]);


    if (!isVisual && defeitoLogisticos.length === 0) return null;
    if (isVisual && manyProduct.length === 0) return null;
    if (manyProduct.length === 0) return null;

    // not sure about this manyProduct.id thing. i think thats just not something that works lol

    return (
        <div key={manyProduct?.id}>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">
                            {/* {manyProduct[0]?.product.name} */}
                        </h1>
                        {/* <p className="mt-2 text-sm text-gray-700">
                            lista de Defeitos para avaliação.
                        </p> */}
                    </div>
                </div>
                <div className="flex flex-col mt-8">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        {isVisual ? (
                            <div className="inline-block min-w-full py-2 overflow-x-auto align-middle md:px-6 lg:px-8 ">
                                {defeitoVisuais && (
                                    <>
                                        <h2 className="text-xl font-semibold text-pink-700">
                                            Defeitos Visuais
                                        </h2>
                                        <br />
                                        <div className="w-full overflow-x-auto">
                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                            >
                                                                Descrição
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                                Quantidade
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                                Comentário
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                        {manyProduct.map(
                                                            (
                                                                defect: any,
                                                                idx: any
                                                            ) => (
                                                                <tr
                                                                    key={`${defect.id}${idx}`}
                                                                    className={
                                                                        "bg-gray-50"
                                                                    }
                                                                >
                                                                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                                                                        {
                                                                            defect.statement
                                                                        }
                                                                    </td>
                                                                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                                        <input
                                                                            id={``}
                                                                            name={``}
                                                                            type="number"
                                                                            min="0"
                                                                            max={1000000}
                                                                            defaultValue={
                                                                                0
                                                                            }
                                                                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                let stateData =
                                                                                    [
                                                                                        ...defectInputChildren,
                                                                                    ];
                                                                                updateData(
                                                                                    idx,
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                );
                                                                                const findDatas =
                                                                                    stateData.findIndex(
                                                                                        ({
                                                                                            id,
                                                                                        }) =>
                                                                                            id ===
                                                                                            defect.id
                                                                                    );

                                                                                if (
                                                                                    findDatas !==
                                                                                    -1
                                                                                ) {
                                                                                    stateData[
                                                                                        findDatas
                                                                                    ].value =
                                                                                        e.target.value;
                                                                                } else {
                                                                                    stateData.push(
                                                                                        {
                                                                                            value: e
                                                                                                .target
                                                                                                .value,
                                                                                            id: defect.id,
                                                                                            type: defect.type,
                                                                                        }
                                                                                    );
                                                                                }
                                                                                setDefectInputChildren(
                                                                                    stateData
                                                                                );
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                                        <input
                                                                            id={`defeitoVisuaisMessage[${defect?.product.name}#${defect?.product.id}][${defect.statement}]`}
                                                                            name={`defeitoVisuaisMessage[${defect?.product.name}#${defect?.product.id}][${defect.statement}]`}
                                                                            type="text"
                                                                            maxLength={500}
                                                                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                let stateData =
                                                                                    [
                                                                                        ...defectInputChildren,
                                                                                    ];
                                                                                updateComment(
                                                                                    idx,
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                );
                                                                                const findDatas =
                                                                                    stateData.findIndex(
                                                                                        ({
                                                                                            id,
                                                                                        }) =>
                                                                                            id ===
                                                                                            defect.id
                                                                                    );

                                                                                if (
                                                                                    findDatas !==
                                                                                    -1
                                                                                ) {
                                                                                    stateData[
                                                                                        findDatas
                                                                                    ].comment =
                                                                                        e.target.value;
                                                                                } else {
                                                                                    stateData.push(
                                                                                        {
                                                                                            comment:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            id: defect.id,
                                                                                            type: defect.type,
                                                                                        }
                                                                                    );
                                                                                }
                                                                                setDefectInputChildren(
                                                                                    stateData
                                                                                );
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                {defeitoLogisticos && (
                                    <>
                                        <br />
                                        <h2 className="text-xl font-semibold text-pink-700">
                                            Defeitos Logísticos
                                        </h2>
                                        <br />
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                        >
                                                            Descrição
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                        >
                                                            Quantidade
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                        >
                                                            Comentário
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {defeitoLogisticos.map(
                                                        (
                                                            defect: any,
                                                            index: any
                                                        ) => (
                                                            <tr
                                                                key={`${defect.id}${index}`}
                                                                className={
                                                                    "bg-gray-50"
                                                                }
                                                            >
                                                                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                                                                    {
                                                                        defect.statement
                                                                    }
                                                                </td>
                                                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                                    <input
                                                                        id={`defeitoLogistico[${defect?.product.name}#${defect?.product.id}][${defect.statement}]`}
                                                                        name={`defeitoLogistico[${defect?.product.name}#${defect?.product.id}][${defect.statement}]`}
                                                                        type="number"
                                                                        min="0"
                                                                        max={1000000}
                                                                        defaultValue={
                                                                            0
                                                                        }
                                                                        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            let stateData =
                                                                                [
                                                                                    ...defectInputChildren,
                                                                                ];
                                                                            updateData(
                                                                                index,
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                            const findDatas =
                                                                                stateData.findIndex(
                                                                                    ({
                                                                                        id,
                                                                                    }) =>
                                                                                        id ===
                                                                                        defect.id
                                                                                );

                                                                            if (
                                                                                findDatas !==
                                                                                -1
                                                                            ) {
                                                                                stateData[
                                                                                    findDatas
                                                                                ].value =
                                                                                    e.target.value;
                                                                            } else {
                                                                                stateData.push(
                                                                                    {
                                                                                        value: e
                                                                                            .target
                                                                                            .value,
                                                                                        id: defect.id,
                                                                                        type: defect.type,
                                                                                    }
                                                                                );
                                                                            }
                                                                            setDefectInputChildren(
                                                                                stateData
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                                    <input
                                                                        id={`defeitoLogisticoMensagem[${defect?.product.name}#${defect?.product.id}][${defect.statement}]`}
                                                                        name={`defeitoLogisticoMensagem[${defect?.product.name}#${defect?.product.id}][${defect.statement}]`}
                                                                        type="text"
                                                                        maxLength={500}
                                                                        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            let stateData =
                                                                                [
                                                                                    ...defectInputChildren,
                                                                                ];
                                                                            updateComment(
                                                                                index,
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                            const findDatas =
                                                                                stateData.findIndex(
                                                                                    ({
                                                                                        id,
                                                                                    }) =>
                                                                                        id ===
                                                                                        defect.id
                                                                                );

                                                                            if (
                                                                                findDatas !==
                                                                                -1
                                                                            ) {
                                                                                stateData[
                                                                                    findDatas
                                                                                ].comment =
                                                                                    e.target.value;
                                                                            } else {
                                                                                stateData.push(
                                                                                    {
                                                                                        comment:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                        id: defect.id,
                                                                                        type: defect.type,
                                                                                    }
                                                                                );
                                                                            }
                                                                            setDefectInputChildren(
                                                                                stateData
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
