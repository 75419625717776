import { useContext } from "react";
import { Switch } from "react-router-dom";

import Dashboard from "../../pages/Dashboard";

import FactorySolicitationForm from "../../pages/FactorySolicitationForm";
import AdminRoute from "../AdminRoute";
import FactorySolicitation from "../../pages/FactorySolicitation";
import Solicitation from "../../pages/Solicitation";
import PreAuditoria from '../../pages/PreAuditoria';
import NoShow from "../../pages/NoShow";
import Checklist from "../../pages/Checklist";
import ResultChecklist from "../../pages/ResultChecklist";

const AuthenticatedRoute = () => (
    <Switch>
        <AdminRoute exact path="/" component={() => <Solicitation />} />
        <AdminRoute
            exact
            path="/preauditoria/:id"
            component={() => <PreAuditoria />}
        />
        <AdminRoute
            exact
            path="/checklist/:id"
            component={() => <Checklist />}
        />
        <AdminRoute
            path="/solicitacao"
            component={() => <FactorySolicitation />}
        />
        <AdminRoute
            path="/solicitacao-formulario"
            component={() => <FactorySolicitationForm />}
        />

        <AdminRoute
            path="/noshow/:id"
            component={() => <NoShow />}
        />

        <AdminRoute
            exact
            path="/resultchecklist/:id"
            component={() => <ResultChecklist />}
        />

        {/* caso a rota não exista */}
        {/* <AdminRoute path="*" component={() => <Redirect to="/" />} /> */}
    </Switch>
);

export default AuthenticatedRoute;
