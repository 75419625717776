import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import DefectsList from "../../components/Checklist/DefectsList";
import ParamentrosList from "../../components/Checklist/ParamentrosList";
import LoadingTable from "../../components/LoadingTable";
import MyDropzone from "../../components/UpDropzoneOnline";
import { Context } from "../../context/AuthContext";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";

interface IAmostragem {
  id: number;
  tamanho_lote: string;
  mesmo_tamanho: number;
  aceitar_pontos_mesmo_tamanho: number;
  reprova_mesmo_ponto_medida: number;
}
interface Inqa {
  id: number;
  level: string;
  level_p: string;
  max_lote: number;
  min_lote: number;
  quanty_a: number;
  quanty_r: number;
  amostragem: number;
}
interface ProductsProps {
  defectsDimensional: {
    id: string;
    parameter: string;
    statement: string;
  }[];
  defectsLogistico: {
    id: string;
    statement: string;
  }[];
  defectsVisual: {
    id: string;
    statement: string;
  }[];
  id: number;
  name: string;
}

export default function Checklist() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getQuestions, setGetQuestions] = useState<boolean>(false);
  const [visualDefectLoaded, setVisualDefectLoaded] = useState<boolean>(false);
  const [obs, setObs] = useState("");
  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(true);
  const [manyProducts, SetManyProducts] = useState<any[]>();
  const [quizList, setQuizList] = useState<any[]>([]);
  const [quizObjects, setQuizObjects] = useState<any[]>([]);
  const [defectsObjects, setDefectObjects] = useState<any[]>([]);
  const [visualDefectList, setVisualDefectList] = useState<any[]>([]);
  const [logisticDefectList, setLogisticDefectList] = useState<any[]>([]);
  const [sizeDefectList, setSizeDefectList] = useState<any[]>([]);
  const [defectOrganizer, setDefectOrganizar] = useState<any[]>([]);
  const [solicitations, setSolicitations] = useState<any>();
  const [defectInputs, setDefectInputs] = useState<any[]>([]);
  const [defectInputsLogistic, setDefectInputsLogistic] = useState<any[]>([]);
  const [defectInputsSizes, setDefectInputsSizes] = useState<any[]>([]);
  const [amostragem, setAmostragem] = useState<IAmostragem>();
  const [nqa, setNqa] = useState<Inqa>();

  // ! pic upload states
  const [uploadPic, setUploadPic] = useState<any[]>([]);
  const [uploadPicDefects, setUploadPicDefects] = useState<any[]>([]);
  const [uploadPicMetricAnalisis, setUploadPicMetricAnalisis] = useState<any[]>(
    []
  );
  const [initialPics, setInitialPics] = useState<any[]>([]);

  const [loadingDropzone, setLoadingDropZone] = useState<boolean>(false);

  const [sectionUploadPic, setSectionUploadPic] = useState<any[]>([]);
  const [sampleData, setSampleData] = useState<any>({});

  const [filesToUpload, setFilesToUpload] = useState<any>([]);
  const [filesToUploadVisualAndLogistic, setFilesToUploadVisualAndLogistic] =
    useState<any>([]);
  const [filesToUploadParameters, setFilesToUploadParameters] = useState<any>(
    []
  );
  const [filesToUploadQuizGroup, setFilesToUploadQuizGroup] = useState<any>([]);
  const modal = useCustomModal();
  const [visualDefectsQuantity, setVisualDefectsQuantity] = useState<any>();
  const [quizOutput, setQuizOutput] = useState<any[]>([]);
  const [outputDimensional, setOutputDimensional] = useState<any[]>([]);
  const [outputLogisticDefects, setOutputLogisticDefects] = useState<any[]>([]);
  const [outputVisualDefects, setOutputVisualDefects] = useState<any[]>([]);

  let { id }: { id: string } = useParams();

  const { handleLogOut, auditor } = useContext(Context);

  // ? get the initial data of the solicitation
  useEffect(() => {
    async function getChecklist() {
      try {
        const { data } = await api.get(`solicitations/${id}`);
        if (data.clothing_set_id) {
          SetManyProducts(data.clothingSet.productSetClothingSets);
        } else {
          SetManyProducts(data.solicitationHasProducts);
        }
        console.log("solicitação ->", data);
        setInitialPics(data.solicitationImages);
        setSolicitations(data);
        setQuizObjects(data.quiz);
        setDefectObjects(data.quizDefects);
        console.log("defeitos ->", data.quizDefects);
        setAmostragem(data.amostragem);
        setNqa(data.nqaTable);
        setIsLoadingScreen(false);

        setGetQuestions(true);
      } catch {
        toast.error("deu ruim");
        // handleLogOut();
      }
    }

    getChecklist();
  }, []);

  function decimalNumber(value: string) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})$/, "$1.$2")
      .replace(/(?=(\d{3})+(\D))\B/g, "");
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  let logisticAndVisualData = [...defectInputsLogistic, ...defectInputs];

  // generate array of arrays
  function generateArray(length: any) {
    const output: any[] = [];
    for (let i = 0; i < length; i++) {
      output.push([]);
    }
    return output;
  }

  // ? length states ajustments
  useEffect(() => {
    if (solicitations) {
      setQuizOutput(
        solicitations.quiz.map((item: any) => ({
          id: item.id,
          response: "Sim",
        }))
      );
      setOutputDimensional(generateArray(solicitations.quizDefects.length));
      setOutputLogisticDefects(generateArray(solicitations.quizDefects.length));
      setOutputVisualDefects(generateArray(solicitations.quizDefects.length));
      setUploadPicDefects(generateArray(solicitations.quizDefects.length));
      setUploadPicMetricAnalisis(
        generateArray(solicitations.quizDefects.length)
      );
      setLoadingDropZone(true);

      console.log(
        "generating ->",
        generateArray(solicitations.quizDefects.length)
      );
    }
  }, [solicitations]);

  // ! send the data to back-end
  async function handleRegisterChecklist(data: any) {
    setIsLoading(true);
    try {
      const outDefects: any = [];

      outputLogisticDefects.forEach((item: any) => {
        item.forEach((values: any) => outDefects.push(values));
      });

      outputVisualDefects.forEach((item: any) => {
        item.forEach((values: any) => outDefects.push(values));
      });

      // !=========================================
      /*const outputPicGroup: any = [];
      quizList.forEach((item: any, idx: number) => {
        outputPicGroup.push({
          groupId: item[0].groupData.id,
          files: sectionUploadPic[idx].map(
            (pic: any, index: number) =>
              `${item[0].groupData.id}_${index}.${pic.path.split(".")[1]}`
          ),
        });
      });

      const outputQuizPics: any[] = [];
      quizList.forEach((item: any, idx: number) => {
        sectionUploadPic[idx].forEach((pic: any, index: number) =>
          outputQuizPics.push({
            name: `${item[0].groupData.id}_${index}.${pic.path.split(".")[1]}`,
            file: pic,
          })
        );
      });
      const outputDimensionalPics: any[] = [];
      const outputDefectPics: any = [];

      uploadPicMetricAnalisis.forEach((pics: any) => {
        pics.forEach((pic: any) => {
          outputDimensionalPics.push(pic);
        });
      });
      uploadPicDefects.forEach((pics: any) => {
        pics.forEach((pic: any) => {
          outputDefectPics.push(pic);
        });
      });*/

      // ! ==============================================
      console.log("envio ->", {
        questions: quizOutput,
        defectsDimensionals: outputDimensional /*dimensionals.map((item) => ({
                    id: item.id,
                    values: item.values,
                })),*/,
        defects: outDefects.map((item: any) => ({
          ...item,
          value: Number(item.value),
        })),
        checklistObservation: obs,
        // groupImages: outputPicGroup,
      });

      const output = {
        questions: quizOutput,
        defectsDimensionals: outputDimensional /*dimensionals.map((item) => ({
                        id: item.id,
                        values: item.values,
                    }))*/,
        defects: outDefects.map((item: any) => ({
          ...item,
          value: Number(item.value),
        })),
        checklistObservation: obs,
        // groupImages: outputPicGroup,
      };

      /*uploadPic.forEach((pic: any) => {
        formData.append("checklistImages", pic);
      });
      outputDimensionalPics.forEach((pic: any) => {
        formData.append("dimensionalImages", pic);
      });
      outputDefectPics.forEach((pic: any) => {
        formData.append("defectsImages", pic);
      });

      outputQuizPics.forEach((item: any) => {
        formData.append("groupImages", item.file, item.name);
      });*/

      try {
        await api.post(
          `checklistAvaliacao/avaliacao/${solicitations.id}`,
          output
        );
        toast.success("auditoria finalizada");
        history.push(`/`);
        setIsLoading(false);
      } catch (e) {
        toast.error("falha ao enviar dados");
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (e) {
      console.log("erro ->", e);
    }
  }

  //  ? common quests ajustments
  useEffect(() => {
    try {
      let quizArrange = quizObjects?.reduce(function (r: any, a: any) {
        r[a.groupData.id] = r[a.groupData.id] || [];
        r[a.groupData.id].push(a);
        return r;
      }, Object.create(null));

      const output: any[] = [];

      Object.keys(quizArrange).forEach((key) => {
        quizList.push(quizArrange[key]);
        output.push([]);
      });

      setSectionUploadPic(output);

      let visualDefectArrange = defectsObjects?.reduce(function (
        r: any,
        a: any
      ) {
        r[a.type] = r[a.type] || [];
        r[a.type].push(a);
        return r;
      },
        Object.create(null));
      Object.keys(visualDefectArrange).forEach((key) => {
        defectOrganizer.push(visualDefectArrange[key]);
      });

      defectOrganizer.forEach(
        (element, index) =>
          element[0].type === "Visual" &&
          visualDefectList.push(element) &&
          setVisualDefectLoaded(true)
      );
      defectOrganizer.forEach(
        (element, index) =>
          element[0].type === "Logistico" && logisticDefectList.push(element)
      );
      defectOrganizer.forEach(
        (element, index) =>
          element[0].type === "Dimensional" && sizeDefectList.push(element)
      );
    } catch {
      toast.error("problema ");
    }
  }, [getQuestions]);

  useEffect(() => {
    console.log("quizlist atualizada ->", quizList);
  }, [quizList]);

  return (
    <>
      <div className="bg-white">
        <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-20 lg:px-8">
          <div className="flex flex-col">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">
                Checklist
              </h2>
              <p className="mt-4 text-lg text-gray-500"></p>
            </div>

            {
              // * texto defeitos visuais
              <div className="mb-4">
                <h3 className="text-2xl font-extrabold text-gray-600">
                  Tabela para Defeitos visuais e logisticos{" "}
                </h3>
                <p className="mt-4 text-lg text-gray-500">
                  <strong>Nível de precificação</strong>: {nqa?.level_p}
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  <strong>Regime de inspeção</strong>: &nbsp;
                  {nqa?.level == "normal" ? "normal" : ""}
                  {nqa?.level == "intermediario_atenuado"
                    ? "intermediário e atenuado"
                    : ""}
                  {nqa?.level == "severo" ? "severo" : ""}
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  <strong>Amostragem</strong>: {nqa?.amostragem}
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  <strong>Tamanho do lote</strong>: {nqa?.min_lote} a{" "}
                  {nqa?.max_lote}
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  <strong>Máximo de erros aceitos</strong>: {nqa?.quanty_a}
                </p>
              </div>
            }

            <hr />

            {
              // * texto defeitos dimensionais
              <div className="mb-4">
                <h3 className="text-2xl font-extrabold text-gray-600">
                  Tabela para Defeitos Dimensionais{" "}
                </h3>
                <p className="mt-4 text-lg text-gray-500">
                  <strong>Plano amostral</strong>: {amostragem?.tamanho_lote}
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  <strong>Quantidade de amostras por tamanho</strong>:{" "}
                  {amostragem?.mesmo_tamanho}
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  <strong>
                    Aceite no mesmo ponto de medida e no mesmo tamanho
                  </strong>
                  : {amostragem?.aceitar_pontos_mesmo_tamanho}
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  <strong>
                    Reprova no mesmo ponto de medida e no mesmo tamanho
                  </strong>
                  : {amostragem?.reprova_mesmo_ponto_medida}
                </p>
              </div>
            }

            {isLoadingScreen ? (
              <LoadingTable />
            ) : (
              <form
                // className="space-y-8 divide-y divide-gray-200"
                action="#"
                method="POST"
                id="auditorRegisterForm"
                onSubmit={handleSubmit(handleRegisterChecklist)}
              >
                <>
                  {solicitations.quizDefects.map(
                    (product: ProductsProps, productIdx: number) => (
                      <div className="mb-4 border-2 border-gray-200 rounded-lg">
                        <div className="m-2 text-3xl text-mainGray">
                          {product.name}
                        </div>
                        {
                          // * defeitos

                          <div className="">
                            {product.defectsVisual.length > 0 && (
                              <>
                                <DefectsList
                                  manyProduct={product.defectsVisual}
                                  register={register}
                                  setValue={setValue}
                                  isVisual={true}
                                  setInput={setOutputVisualDefects}
                                  input={outputVisualDefects}
                                  product={productIdx}
                                />
                                <br />
                              </>
                            )}
                            {product.defectsLogistico.length > 0 && (
                              <>
                                <DefectsList
                                  manyProduct={product.defectsLogistico}
                                  register={register}
                                  setValue={setValue}
                                  setInput={setOutputLogisticDefects}
                                  input={outputLogisticDefects}
                                  product={productIdx}
                                />
                                <br />
                              </>
                            )}

                            {product.defectsLogistico.length > 0 &&
                              product.defectsVisual.length > 0 && (
                                <>
                                  <div className="w-full px-8 mb-4">
                                    Upload de imagens
                                  </div>
                                  <div className="flex flex-wrap justify-start w-full gap-4 px-2 mb-8 sm:px-8">
                                    {loadingDropzone && (
                                      <MyDropzone
                                        initialFiles={initialPics}
                                        disabled={false}
                                        fieldName={"defectsImages"}
                                        checkId={id}
                                      />
                                    )}
                                  </div>
                                  <hr />
                                </>
                              )}
                          </div>
                        }
                        <div className="w-full h-8" />
                        {
                          // * analise de medidas
                          <div className="w-full ">
                            <ParamentrosList
                              defects={product.defectsDimensional}
                              setValue={setOutputDimensional}
                              value={outputDimensional}
                              samples={solicitations.amostragem.mesmo_tamanho}
                              product={productIdx}
                            />
                            <div className="px-2 sm:px-8">
                              <div className="w-full mt-8 mb-4">
                                Upload de imagens
                              </div>
                              <div className="flex flex-wrap justify-start w-full gap-4 mb-8 ">
                                {loadingDropzone && (
                                  <MyDropzone
                                    initialFiles={initialPics}
                                    disabled={false}
                                    checkId={id}
                                    fieldName={"dimensionalImages"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    )
                  )}
                </>
                <div className="gap-4 mt-10 mb-10">
                  <div>
                    {quizList.length > 0 && (
                      <div className="text-[30px] font-bold mb-6">
                        Perguntas adicionais
                      </div>
                    )}
                    {quizList?.map((quiz: any, index: number) => {
                      return (
                        <div className="px-2 mb-2 border-2 rounded-lg">
                          <div className="text-[23px] mb-2">
                            {quiz[0].groupData.name}
                          </div>
                          <div className="text-sm ">
                            <div>
                              {quiz?.map((questions: any, index: number) => {
                                return (
                                  <div className="flex flex-wrap justify-between mb-2 gap-y-2">
                                    <div>{questions.question}</div>
                                    <div className="mr-10">
                                      <select
                                        id="state"
                                        name="state"
                                        autoComplete="state-name"
                                        className="block w-full px-3 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                          setQuizOutput(
                                            quizOutput.map((item: any) =>
                                              item.id === questions.id
                                                ? {
                                                  ...item,
                                                  response: e.target.value,
                                                }
                                                : item
                                            )
                                          );
                                        }}
                                      >
                                        <option value={`Sim`}>Sim</option>
                                        <option value={`Não`}>Não</option>
                                        <option value={`Não interfere`}>
                                          Não aplicavel
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="flex flex-wrap mb-4 ...">
                            <div className="w-full mt-8 mb-4">
                              Upload de imagens
                            </div>
                            <div className="flex flex-wrap justify-start w-full gap-4 mb-8 ">
                              {loadingDropzone && (
                                <MyDropzone
                                  disabled={false}
                                  initialFiles={initialPics}
                                  fieldName={"groupImages"}
                                  checkId={id}
                                  groupId={quiz[0].group.id}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* ------------------------- */}
                <label
                  htmlFor="noshowText"
                  className="block text-sm font-medium text-gray-700"
                >
                  Observação
                </label>
                <div className="mt-1">
                  <textarea
                    {...register("checklistText")}
                    id="checklistText"
                    name="checklistText"
                    rows={4}
                    value={obs}
                    maxLength={500}
                    onChange={(e) => setObs(e.target.value)}
                    className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    defaultValue={""}
                  />
                  {/* {errors.checklistText && (
                    <span className="text-mainDarkRed">
                      {errors.checklistText.message}
                    </span>
                  )} */}
                </div>
                <br />
                <div className="flex flex-wrap mb-4 ...">
                  <div className="w-full mt-8 mb-4">Upload de imagens</div>
                  <div className="flex flex-wrap justify-start w-full gap-4 mb-8 ">
                    {
                      <MyDropzone
                        disabled={false}
                        initialFiles={initialPics}
                        fieldName={"checklistImages"}
                        checkId={id}
                      />
                    }
                  </div>
                </div>
                <br /> <br />
                <div className="w-full md:w-32">
                  <PrimaryButton
                    title="Confirmar"
                    form="auditorRegisterForm"
                    isLoading={isLoading}
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
